define('login',['jquery',
    'helpers',
    'alertify',
    'text!./Modules/login/link.html',
    'mustache',
    'events',
    'jquery-validate',
    'jquery-validate-unobtrusive',
    'jquery-unobtrusive-ajax'
    ],
    function login(
        $,
        helpers,
        alertify,
        tplLink,
        mustache,
        events
    ) {

        //Scripts for the login panel in the header of all pages

        function submitLoginForm() {
            $('#loginLink').click(function () {
                if (!$('#loginForm').valid()) {

                    //duplicate value of #email into emailHidden hidden field so that the value can be submitted by the sendForgottenPasswordForm
                    $('#emailHidden').val($(this).val());

                    helpers.alertifyMvcValidationErrorsUnderId("#loginFormDiv");
                    return false;
                }
                else {
                    $('#loginForm').submit();
                    return false;
                }
            });

            $('#email').change(function (e) {
                $("#emailHidden").val($("#email").val());
            });
            $('#email').keypress(function (e) {
                if (e.which == 13) {
                    $(this).change(function () { }); //Cancel the submit on change, otherwise the form will be submitted twice
                    $('#loginForm').submit();
                    return false;
                }
            });

            $('#password').keypress(function (e) {
                if (e.which == 13) {
                    $(this).change(function () { }); //Cancel the submit on change, otherwise the form will be submitted twice
                    $('#loginForm').submit();
                    return false;
                }
            });
        };

        //Ajax loginForm global functions

        //OnSuccess
        window.alertifyLoginFormValidationErrorAndSummary = function (ajaxContext) {
            submitLoginForm();

            //Update the value of all Asp.NET AntiForgeryToken on the page 
            helpers.updateAntiForgeryTokenInput();

            //Why are we using #loginSignedOutDiv as startingElementId for helpers.alertifyMvcValidationErrorsUnderId() ?
            //We need to alertify messages for both #loginForm and #sendForgottenPasswordForm as they share the Email input and have both a @Html.ValidationMessageFor(m => m.Email) , so if there is a server - side validation on the Email, it will be output twice.
            //The common element enclosing both #loginForm and #sendForgottenPasswordForm is #loginSignedOutDiv, so that's the one to use as startingElementId for helpers.alertifyMvcValidationErrorsUnderId()
            helpers.alertifyMvcValidationErrorsUnderId("#loginFormDiv");
            helpers.alertifyMvcValidationSummary();

            //Update FavoritesRow if there is one on the page (this function is defined in favoriteSitesRow.js which is included by all pages displaying the _FavoriteSitesRow partial view)
            if ($.isFunction(window.updateFavoriteSitesRowIfAny)) {
                updateFavoriteSitesRowIfAny();
            }

            if ($("header.site-header input[name='login']").length > 0) {
                $("#playersLinks").empty();
                $("#playersLinks").append(mustache.render(tplLink, {}));
                $("#logo-title-js").html("Courts");
                headerHover();
            }
            window.pubsub.publish(events.AFTER_LOGIN_SUCCES, []);
        };

        //OnFailure
        window.displayLoginFormDefaultAjaxError = function (ajaxContext) {

            //Update the value of all Asp.NET AntiForgeryToken on the page 
            helpers.updateAntiForgeryTokenInput();

            submitLoginForm();
            var failureMessage = $("#loginFormOnFailureMessage").html();
            alertify.error(failureMessage);
        };

        //Ajax sendForgottenPasswordForm functions

        //OnSuccess
        window.alertifySendForgottenPasswordFormValidationErrorAndSummary = function (ajaxContext) {
            //See "Why are we using #loginSignedOutDiv as startingElementId for helpers.alertifyMvcValidationErrorsUnderId() ?" above
            var formHadValidationError = helpers.alertifyMvcValidationErrorsUnderId('#sendForgottenEmailFormDiv');

            if (!formHadValidationError) {
                var successMessage = $("#sendForgottenPasswordFormOnSuccessMessage").html();
                alertify.success(successMessage);
            }
        };

        //OnFailure
        window.displaySendForgottenPasswordDefaultAjaxError = function (ajaxContext) {
            var failureMessage = $("#sendForgottenPasswordFormOnFailureMessage").html();
            alertify.error(failureMessage);
        };

        function _bindEvents() {
            $("#loginForm").submit(function () {
                //See "Why are we using #loginFormDiv as startingElementId for helpers.alertifyMvcValidationErrorsUnderId() ?" above
                helpers.alertifyMvcValidationErrorsUnderId("#loginFormDiv");
            });
        }

        function headerHover() {
            var classToDeleteOnHover = "";
            if ($('body').hasClass("profil-page")) {
                classToDeleteOnHover = classToDeleteOnHover + "profil-page";
            }
            if ($('body').hasClass("court-page")) {
                classToDeleteOnHover = classToDeleteOnHover + " court-page";
            }
            if ($('body').hasClass("player-page")) {
                classToDeleteOnHover = classToDeleteOnHover + " player-page";
            }
            if ($('body').hasClass("coach-page")) {
                classToDeleteOnHover = classToDeleteOnHover + " coach-page";
            }
            if ($('body').hasClass("cordeurs-page")) {
                classToDeleteOnHover = classToDeleteOnHover + " cordeurs-page";
            }
            $('.header-brand').hover(function () {
                $('body').removeClass(classToDeleteOnHover);
            }, function () {
                $('body').addClass(classToDeleteOnHover);
            });
            $('.right-panel').not("#loginSignedOutDiv").hover(function () {
                $('body').removeClass(classToDeleteOnHover);
            }, function () {
                $('body').addClass(classToDeleteOnHover);
            });
        }

        function _init() {

            _bindEvents();

            submitLoginForm();

            headerHover();
        }

        /////////////////////////////

        _init();

    });

