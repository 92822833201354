define('showHidePassword',['jquery'],
    function showHidePassword(
        $
    ) {

        function _init() {
            $(".input-password-addon a").on('click', function (event) {
                event.preventDefault();
                if ($(this).parents('.input-group-password').find('input').attr("type") == "text") {
                    $(this).parents('.input-group-password').find('input').attr('type', 'password');
                    $(this).find('i').removeClass("icon-eye-open");
                    $(this).find('i').addClass("icon-eye-close");
                } else if ($(this).parents('.input-group-password').find('input').attr("type") == "password") {
                    $(this).parents('.input-group-password').find('input').attr('type', 'text');
                    $(this).find('i').removeClass("icon-eye-close");
                    $(this).find('i').addClass("icon-eye-open");
                }
            });
        }

        /////////////////////////////

        _init();

    });

