'use strict';

define('helpers',['jquery',
    'isMobile',
    'alertify',
    'underscore'],
    function ($,
        isMobile,
        alertify,
        _) {
		'use strict';
		function Helpers() {

            var self = this;

            //General variables shared accross all helpers functions
            var zoomFactor = 1.5;
            var zoomMinScreenSize = "1024px";

			// Pre selecting common DOM elements so we can safely bind/unbind multiple event handlers.
			self.$body = $('body');
			self.$html = $('html');
			self.$document = $(document);
			self.$window = $(window);

			// Debounce handler.
			self.debounce = function (func, wait, immediate) {
				var timeout;
				return function () {
					var context = this,
						args = arguments,
						callNow = immediate && !timeout,
						later = function () {
							timeout = null;
							if (!immediate) {
								func.apply(context, args);
							}
						};
					clearTimeout(timeout);
					timeout = setTimeout(later, wait);
					if (callNow) {
						func.apply(context, args);
					}
				};
			};

			/**
			 * Simple scrollTo handler.
			 * @param selector
			 */
			self.scrollTo = function (selector) {
				var result;
				var $target = $(selector);

				if (!$target.length) {
					result = (_.isNumber(selector)) ? Number(selector) : 0;
				}
                else {
                    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

                    // scrollTop FIX for EDGE which is expecting for an element the scrollTop to be the distance from the top, rather than the distance from the current position to the element (as for any other browser)
                    // Note: If this is not resilient enough check out modernizr.
                    if ((isSafari) || (!!navigator.userAgent.match(/Edge/i))) {
                        if ($target.selector != self.$body.selector) {
                            var rect = $target.getBoundingClientRect();
                            var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                            result = rect.top + scrollTop;
                        }
                        else {
                            //body doesn't support getBoundingClientRect(), so if $target = body, we just set result to 0 as the body element is at the very top of the page
                            result = 0;
                        }
                    }
                    else {
                        //result = $target.offset().top //this line is not working when a zoom is applied to the page, so use the next one instead which seems to be working fine
                        result = $target.scrollTop();
                    }
				}

				[self.$body, self.$html].forEach(function ($el) {
					$el.stop(true, true).animate({
						scrollTop: result
					}, 500);
				});
			};

			self.getPageHeight = function () {
				return self.$window.height();
			};

			// From Modernizr
			self.whichTransitionEvent = function () {
				var t;
				var el = document.createElement('fakeelement');
				var transitions = {
					'transition': 'transitionend',
					'OTransition': 'oTransitionEnd',
					'MozTransition': 'transitionend',
					'WebkitTransition': 'webkitTransitionEnd'
				};

				for (t in transitions) {
					if (el.style[t] !== undefined) {
						return transitions[t];
					}
				}
			};

			self.getId = function(value) {
				return value.toLowerCase();
			};

			self.formatError = function(jqXHR, textStatus, errorThrown) {
				var msg;

				if (jqXHR && jqXHR.status === 0) {
					msg = 'Not connected.\nPlease verify your network connection.';
				} else if (jqXHR && jqXHR.status == 404) {
					msg = 'The requested page not found. [404]';
				} else if (jqXHR && jqXHR.status == 500) {
					msg = 'Internal Server Error [500].';
				} else if (textStatus === 'parsererror') {
					msg = 'Requested JSON parse failed.';
				} else if (textStatus === 'timeout') {
					msg = 'Time out error.';
				} else if (textStatus === 'abort') {
					msg = 'Ajax request aborted.';
				} else if(jqXHR) {
					msg = 'Uncaught Error.\n' + jqXHR.responseText;
				} else if(textStatus) {
				    msg = textStatus
                }

				return msg;
			};

			self.geoLocationErrorHandling = function(error) {
				var msg;

				switch(error.code) {
					case error.PERMISSION_DENIED:
						msg = "User denied the request for Geolocation.";
						break;
					case error.POSITION_UNAVAILABLE:
						msg = "Location information is unavailable.";
						break;
					case error.TIMEOUT:
						msg = "The request to get user location timed out.";
						break;
					case error.UNKNOWN_ERROR:
						msg = "An unknown error occurred.";
						break;
				}
			};

			/**
			 * Gets the name of a day 'e.g. jeudi' and the day number (e.g. 04) and returns
			 * the first character from the dayname followed by a 2 digit
			 */
			self.formatDay = function(dayName, dayNumber) {
				return dayName.split('')[0] + dayNumber;
			};


			self.isEmpty = function(data) {
				for(var key in data) {
					if(data.hasOwnProperty(key))
						return false;
				}
				return true;
			};

			/**
			 * By using isMobile library returns either click or touch event handler.
			 * @returns {string}
			 */
			self.getEventHandler = function() {
				return isMobile.any ? 'touch' : 'click';
			};

            /**
             * Fix for carousel swiping issue on edge
             * @documentation: https://github.com/svnsmsh/7smash-website/issues/70
             * @param swiper
             */
            self.fixSwipperTranslateValueWhenZoomed = function(swiper) {
                // ZOOM FIX for EDGE
                // Note: If this is not resilient enough check out modernizr.
                if (!!navigator.userAgent.match(/Edge/i)) {
                    if (window.matchMedia("(min-width: " + zoomMinScreenSize + ")").matches) {

                        swiper.setTranslate(swiper.translate * zoomFactor, '', true);

                    }
                }
            };

             /**
             * Removes each MVC validation error in forms generated by a MVC @Html.BeginForm helper
             * under the element whose id is the specified startingElementId
             */
            self.removeAllMvcValidationErrorsUnderId = function (startingElementId) {
                if ($(startingElementId + " span.field-validation-error").length != 0)
                    $(startingElementId + " span.field-validation-error").each(function () {
                        $(this).remove();
                    });
            };

            /**
             * Alertify each validation error generated by a MVC @Html.BeginForm helper
             * under the element whose id is the specified startingElementId
             * Make border red for each input with a validation error
             */
            self.alertifyMvcValidationErrorsUnderId = function (startingElementId) {
                //Reset border color of all valid inputs
                if ($(startingElementId + " span.field-validation-valid").length != 0)
                    $(startingElementId + " span.field-validation-valid").each(function () {
                        var inputID = $(this).prev("input").attr("id");//get the id of the input field for which this validation is prompted
                        $(this).prev("input").css("border-color", "");
                    });

                //Set border color of all unvalid inputs and alertify validation messages not hidden
                var displayedValidationMessageList = [];
                if ($(startingElementId + " span.field-validation-error").length != 0)
                    $(startingElementId + " span.field-validation-error").each(function () {
                        $(this).addClass("hidden");//Add class hidden to hide @Html.ValidationMessageFor(model => model.OneModelProperty)
                        var inputID = $(this).prev("input").attr("id");//get the id of the input field for which this validation is prompted
                        $(this).prev("input").css("border-color", "red");
                        var validationMessage = $(this).text();//Get validation message for input filed which is prompted
                        //If we haven't alertified the same validation error already (in case of multiple forms on the same page using the same property)
                        if (!displayedValidationMessageList.includes(validationMessage)) {
                            displayedValidationMessageList.push(validationMessage);
                            alertify.error(validationMessage);
                        }
                    });

                return Boolean(displayedValidationMessageList.length > 0);
            };

            /**
             * Alertify the ValidationSummary of forms generated by a MVC @Html.BeginForm helper
             */
            self.alertifyMvcValidationSummary = function() {
                //Reset border color of all valid inputs
                if ($("div.validation-summary-errors").length != 0) {
                    $("div.validation-summary-errors").each(function () {
                        $(this).addClass("hidden");
                        var validationSummaryHtmlString = $(this).html();
                        if (validationSummaryHtmlString.length != 0) {
                            var validationSummaryErrors = ""
                            $(validationSummaryHtmlString).find("li").each(function () {
                                var liText = $(this).text();
                                if (liText.length > 0) {
                                    if (validationSummaryErrors.length > 0)
                                        validationSummaryErrors += "<br/>";
                                    validationSummaryErrors += liText;
                                }
                            });
                            if (validationSummaryErrors.length > 0)
                                alertify.error(validationSummaryErrors);
                        }
                    });
                }
            };

            /**
             * Add new error message to the first MVC ValidationSummary found under the specified startingElementId and optionnaly clear all other errors and remove the hidden class on the validation summary.
             * If there is no validation summary under the specified startingElementId, create one
             */
            self.addErrorMessageToMvcValidationSummary = function (startingElementId, errorMessage, removeOtherErrors, removeValidationSummaryHiddenClass) {

                //As we support ie11, we cannot use default values for parameters
                if (removeOtherErrors === undefined) {
                    removeOtherErrors = false;
                }
                if (removeValidationSummaryHiddenClass === undefined) {
                    removeValidationSummaryHiddenClass = false;
                }

                if ($(startingElementId).find('div.validation-summary-errors').length <= 0) {
                    var div = document.createElement('div');
                    div.setAttribute('class', 'validation-summary-errors text-danger');
                    $(startingElementId).append(div);
                }

                $(startingElementId + ' div.validation-summary-errors').first().each(function () {
                    if (removeValidationSummaryHiddenClass == true) {
                        $(this).removeClass("hidden");
                    }

                    if ($(this).find('ul').length <= 0) {
                        var ul = document.createElement('ul');
                        $(this).append(ul);
                    }

                    $(this).find("ul").first().each(function () {
                        if (removeOtherErrors == true) {
                            $(this).find('li').remove();
                        }
                        var li = document.createElement('li');
                        li.setAttribute('class', 'item');
                        $(this).append(li);
                        li.innerHTML = li.innerHTML + errorMessage;
                    });
                });
            };

            /**
             * Returns the index of the slide with the slide with the specified className in the swiper carousel with the specified rootCssClass
             */
            self.getSlideIndexByClass = function (rootCssClass, className) {
                var index = 0;
                $.each($(rootCssClass + ' .swiper-wrapper').children(), function (i, item) {
                    if ($(item).hasClass(className)) {
                        index = i;
                        return false;
                    }
                });
                return index;
            };

            /**
             * Redirect to the specified url using the POST verb and passing the speicifed data.
             * Example of use : redirectPost('http://www.example.com', { text: 'text\n\ntext' });
             * see https://stackoverflow.com/a/38445519
             */
            self.redirectPost = function redirectPost(url, data) {
                var form = document.createElement('form');
                document.body.appendChild(form);
                form.method = 'post';
                form.action = url;
                for (var name in data) {
                    var input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = name;
                    input.value = data[name];
                    form.appendChild(input);
                }
                form.submit();
            }

            /**
             * Update the value of all Asp.NET AntiForgeryToken on the page (which are input with name = '__RequestVerificationToken') with a new up-to-date token working for the currently logged-in user (usefull when you log-in or log-out and stay on the same page)
             */
            self.updateAntiForgeryTokenInput = function updateAntiForgeryTokenInput() {
                var newRequestVerificationToken = this.getAntiForgeryTokenValue();
                $("input[name='__RequestVerificationToken']").val(newRequestVerificationToken);
            }

            /**
             * Update the value of all Asp.NET AntiForgeryToken on the page (which are input with name = '__RequestVerificationToken') with a new up-to-date token working for the currently logged-in user (usefull when you log-in or log-out and stay on the same page)
             * @returns {string}
             */
            self.getAntiForgeryTokenValue = function getAntiForgeryTokenValue() {
                var requestVerificationToken;
                $.ajax({
                    async: false,
                    type: 'GET',
                    url: '/Account/RefreshToken',
                    success: function (htmlResponse) {
                        var $antiForgeryTokenInput = $(htmlResponse);
                        var newRequestVerificationToken = $antiForgeryTokenInput.val();
                        requestVerificationToken = newRequestVerificationToken;
                    }
                });
                return requestVerificationToken;
            }
		}

		return new Helpers();
	});

