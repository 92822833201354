define('cookieConsent',['jquery',
    'helpers'
    ],
    function cookieConsent(
        $,
        helpers) {

        //Scripts for the cookie consent panel

        function _bindEvents() {

            $('#consentButton').click(function () {
                document.cookie = "sevensmash-cookie-consent=yes; " +
                    "domain=" + location.hostname + "; " +
                    "path=/;" +
                    "max-age=" + (1 * 365 * 24 * 60 * 60); // Set the cookie to expire in 1 year
                $('#cookie-consent').slideUp('slow');
            });

        }

        function _init() {

            _bindEvents();

            if (document.cookie.indexOf("sevensmash-cookie-consent=yes") < 0) {
                $('#cookie-consent').slideDown('slow');
            }
        }

        /////////////////////////////

        _init();

    });

