'use strict';
/**
 * Events that can be triggered by various components
 */
define('events',[], function () {
    return {
        BOOKING_TIME_SELECTED: 'custom_booking_time_selected',
        DAY_CHANGED: 'custom_day_changed',
        DATE_CHANGED: 'custom_date_changed',
        FILTERS_CHANGED: 'custom_filters_changed',
        UPDATE_PRICE: 'custom_update_price',
        CLEAR_COURTS: 'custom_clear_courts',
        UPDATE_SITE_TITLE: 'custom_update_site_title',
        DEACTIVATE_SEARCH_FORM_SUBMIT: 'custom_deactivate_search_button',
        PLACE_CHANGED: 'custom_place_changed',
        UPDATE_AVAILABILITY: 'update_availability',
        AFTER_LOGIN_SUCCES: 'after_login_succes'
    };
});

